import https from "./https";
import store from "@/store";

const pointLog = {
  getPointLogs(merchantId, params) {
    const organization = store.state.general.organization;
    const searchParams = new URLSearchParams(params);

    return https.get(
      `/admin/organizations/${organization.id}/merchants/${merchantId}/point-logs?${searchParams}`
    );
  },

  getPoint(pointLogId) {
    return https.get(`/admin/point-logs/${pointLogId}/point`);
  },

  getRedeemLogs(merchantId, params) {
    const organization = store.state.general.organization;
    const searchParams = new URLSearchParams(params);

    return https.get(
      `/admin/organizations/${organization.id}/merchants/${merchantId}/redeem-logs?${searchParams}`
    );
  },

  exportPointLogs(params, merchantId) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/merchants/${merchantId}/point-logs/export`, {params})
  }
};

export default pointLog;
